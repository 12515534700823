import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import IntrinsicElementWrapper from '../IntrinsicElementWrapper/IntrinsicElementWrapper';
import styles from './Details.module.scss';


const Details = ({ children, summary }) => {
  const [detailsAs, setDetailsAs] = useState('details');

  useEffect(() => {
    const detailsElement = document.createElement('details');
    if (!('open' in detailsElement)) setDetailsAs('div');
  }, []);

  return (
    <IntrinsicElementWrapper as={detailsAs} className={styles.details}>
      <summary className={`${styles.summary} ${detailsAs !== 'details' ? styles.summaryHideMarker : ''}`}>
        <span className={styles.summaryText}>{summary}</span>
      </summary>
      {children}
    </IntrinsicElementWrapper>
  );
};

Details.propTypes = {
  children: PropTypes.node,
  summary: PropTypes.node,
};

export default Details;