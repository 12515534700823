import React from 'react';
import ColoredText from '../../components-lib/ColoredText/ColoredText';
import Details from '../../components-lib/Details/Details';
import Article from '../../components/Article/Article';


const CausesOfDiscrimination = () => (
  <Article
    title={<span>Diskriminačné <ColoredText>dôvody</ColoredText></span>}
    imageAlt="Ilustrácia mysliacej skupiny ľudí"
    imageLink="/svg/illustrations/discrimination-reasons.svg"
  >
    <p>
      Rozdielne zaobchádzanie je v prípade diskriminácie podmienené rôznymi odlišnosťami človeka. Dodržiavanie zásady rovnakého zaobchádzania spočíva v zákaze diskriminácie z dôvodu pohlavia, náboženského vyznania alebo viery, rasy, príslušnosti k národnosti alebo etnickej skupine, zdravotného postihnutia, veku, sexuálnej orientácie, manželského stavu a rodinného stavu, farby pleti, jazyka, politického alebo iného zmýšľania, národného alebo sociálneho pôvodu, majetku, rodu alebo iného postavenia, alebo z dôvodu oznámenia kriminality, alebo inej protispoločenskej činnosti.
    </p>
    <Details summary="Diskriminácia z dôvodu pohlavia a rodu">
      <p>
        Pohlavie je súhrn podstatných biologických znakov, ktorými sa odlišujú muži a ženy. Rod je vnútorná osobná identifikácia jedinca s mužským alebo ženským vnímaním seba samého. Náš ADZ nerozlišuje medzi pojmom pohlavie a rod.
      </p>
      <p>
        Ak v praxi dôjde k diskriminácii podľa rodu, tá často napĺňa skutkovú podstatu diskriminácie podľa pohlavia. Za diskrimináciu z dôvodu pohlavia sa považuje aj diskriminácia z dôvodu tehotenstva alebo materstva, ako aj z dôvodu zmeny pohlavia osoby.
      </p>
      <p>
        Príklad: Pretrvávajú výrazné rozdiely v platovom ohodnotení žien a mužov, keď ženy zarábajú za porovnateľnú prácu v priemere o 18% menej ako muži. Napriek veľmi dobrej vzdelanostnej úrovni žien (na Slovensku dokonca vyššej ako u mužov), ženy nedosahujú porovnateľné zárobky s mužmi, nakoľko vzdelanie v typicky ženských oblastiach je oceňované nižšie.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu náboženského vyznania a viery">
      <p>
        Náboženské vyznanie možno definovať ako presvedčenie o existencii Boha alebo bohov alebo taktiež formálne prejavy tohto presvedčenia. Viera je vnútorné presvedčenie o jestvovaní, o správnosti alebo o pravde niečoho, čo nie je bezprostredne a neochvejne preukázané nespochybniteľnými dôkazmi.
      </p>
      <p>
        Príklad: Zamestnávateľ zakázal na pracovisku nosenie burky a hidžábu. Ostatní zamestnanci mali dovolené náboženské symboly. V tomto prípade ide o diskrimináciu z dôvodu náboženského vyznania a viery, pretože zamestnávateľ môže zakázať buď všetky náboženské symboly, alebo žiadne, a preto sa nemôže rozhodnúť tak, že zakáže nosenie iba moslimského symbolu náboženstva.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu rasy, farby pleti alebo príslušnosti k etnickej skupine">
      <p>
        Pojem rasa možno vymedziť ako skupinu ľudí, ktorých spája dlhodobý spoločný vývoj, v ktorého priebehu sa vplyvom rozdielneho prostredia vytvorili zhodné morfologické a fyziologické znaky (farba pleti, farba a tvar vlasov, oči, rysy tváre, tvar lebky, nosu atď.). Je potrebné ho uplatňovať v spojitosti s pojmom „farba pleti“.
      </p>
      <p>
        Rasová diskriminácia sa prejavuje vo viacerých oblastiach života – vzdelávanie, prístup k službám a tovarom, nerovný prístup pri poskytovaní zdravotnej starostlivosti a bývaniu.
      </p>
      <p>
        Etnická skupina je demografická skupina ľudí na určitom štáte alebo regióne, ktorá sa odlišuje od väčšiny obyvateľstva určitými osobnými znakmi, ktorými môžu byť jazyk, rasa, náboženstvo alebo iné faktory.
      </p>
      <p>
        Príklad: Recepčná odmietla v hoteli ubytovať 2 hostí čiernej farby pleti s odôvodnením, že takýto ľudia by sa mali vrátiť do Afriky. Okrem iného ide aj o prejav diskriminácie z dôvodu rasy.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu zdravotného postihnutia">
      <p>
        Ide o obmedzenie vyplývajúce najmä z fyzického, mentálneho alebo psychického postihnutia, ktoré bráni účasti dotknutej osoby na profesijnom živote a zároveň musí byť pravdepodobné, že bude trvať dlhú dobu.
      </p>
      <p>
        Pri zdravotnom postihnutí sa za diskrimináciu považuje, ak sa nerovnako zaobchádza s osobou na základe predchádzajúceho postihnutia alebo len preto, že na základe vonkajších príznakov by sa mohlo zdať, že ide o osobu so zdravotným postihnutím.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu veku">
      <p>
        Vek je obdobie ľudského života udávané v rokoch. Diskriminácia sa najčastejšie vyskytuje v pracovnoprávnych vzťahoch. Ide predovšetkým o obmedzenie prístupu osôb k niektorým verejným funkciám, služobnému pomeru príslušníkov ozbrojených zborov a pod.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu sexuálnej orientácie">
      <p>
        Sexuálna orientácia označuje preferenciu pri sexuálnej voľbe, ktorá je heterosexuálna, homosexuálna alebo bisexuálna, pričom asexualita sa niekedy označuje ako štvrtá kategória.
      </p>
      <p>
        Príklad: Zamestnávateľ odmietol prijať uchádzača o zamestnanie na základe toho, že počas pohovoru zistil, že je homosexuál.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu manželského stavu a rodinného stavu">
      <p>
        Podľa čl. 1 a 2 zákona č. 36/2005 Z.z. o rodine: „Manželstvo je zväzkom muža a ženy. Spoločnosť tento jedinečný zväzok všestranne chráni a napomáha jeho dobru. Manžel a manželka sú si rovní v právach a povinnostiach. Hlavným cieľom manželstva je založenie rodiny a riadna výchova detí. Rodina založená manželstvom je základnou bunkou spoločnosti. Spoločnosť  všetky formy rodiny všestranne chráni.“
      </p>
      <p>
        Rodinný stav patrí popri mene, mieste a dátume narodenia alebo počte detí k osobnej charakteristike osoby a udáva, či je táto osoba slobodná, rozvedená, ovdovelá alebo či ide o ženatého muža/vydatú ženu.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu jazyka">
      <p>
        Jazyk je sústavou vyjadrovacích znakových prostriedkov istého spoločenstva, ktorý slúži ako nástroj myslenia, dorozumievania a ukladania poznatkov tohto spoločenstva. Právo používať jazyk národnostných menšín a etnických skupín v úradnom styku (napr. súdy) upravujú osobitné predpisy, najmä zákon č. 270/1995 o štátnom jazyku SR.
      </p>
      <p>
        Príklad: Počas súdneho procesu nebolo žalovanej, ktorá je príslušníčka ukrajinskej národnostnej menšiny, umožnené využiť služby tlmočníka napriek tomu, že slovenský jazyk vôbec neovládala, a tak bolo porušené jej právo na rovnosť pred zákonom na základe jazyka, čím bolo porušené jej právo na rovnosť pred zákonom na základe jazyka.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu politického alebo iného zmýšľania">
      <p>
        Politické alebo iné zmýšľanie má svoj základ v Ústave SR, ADZ a mnohých medzinárodných zmluvách. Osobitným zákonom, ktorý priznáva osobám konkrétne práva, v ktorých je potrebné rešpektovať a dodržiavať zásadu rovnakého zaobchádzania, je napríklad zákon č. 85/2005 Z.z. o politických stranách a politických hnutiach.
      </p>
      <p>
        ESĽP (Európsky súd pre ľudské práva) konštatoval vo veci Handyside proti Spojené kráľovstvo, že právo na slobodu prejavu bude chrániť nielen „informácie“ alebo „myšlienky“, ktoré sú prijímané priaznivo alebo sa považujú za neurážlivé alebo indiferentné, ale aj tie, ktoré sú urážlivé, šokujúce alebo znepokojujúce pre štát alebo časť populácie.
      </p>
      <p>
        Príklad: Poslanec bol stíhaný za „urážku“ vlády po kritike nečinnosti vlády pri riešení teroristických činov v Baskicku. ESĽP zdôraznil význam slobody prejavu v politických súvislostiach, a to najmä vzhľadom na jeho dôležitú úlohu v riadne fungujúcej demokratickej spoločnosti. V tejto súvislosti ESĽP konštatoval, že akýkoľvek zásah by si vyžadoval „čo najpodrobnejšie preskúmanie“.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu príslušnosti k národnosti a národného alebo sociálneho pôvodu">
      <p>
        Národ je historicky vzniknuté spoločenstvo ľudí so spoločným jazykom, územím, hospodárstvom, históriou, kultúrou, psychikou, náboženstvom a podobne, ktoré má spravidla vôľu tieto spoločné faktory brániť či rozvíjať. Etnický národ tvoria všetci jedinci, ktorí hovoria rovnakým jazykom, zdieľajú rovnakú históriu a kultúru, a na druhej strane politickým národom sú všetci jedinci, ktorí sú teritoriálne sústredení v štáte, sú jeho občanmi a môžu sa politicky angažovať.
      </p>
      <p>
        „Národnostný pôvod“ sa môže použiť na označenie bývalej štátnej príslušnosti osoby, ktorá mohla o ňu prísť alebo ju mohla získať na základe naturalizácie, alebo sa vzťahovať na príslušnosť k „národu“ v rámci štátu (napríklad ako Škótsko v Spojenom kráľovstve).
      </p>
      <p>
        „Sociálny pôvod“ sa týka životných podmienok jednotlivca vo vzťahu k spoločnosti a štátu, k jeho hmotnému a všestrannému zabezpečeniu v spoločnosti, pričom jednotlivec v týchto podmienkach začínal, vyrastal alebo je s nimi inak spätý. Predstavuje spoločenský status osoby založený v jej pôvode, na ktorý sa vzťahuje dodržiavanie zásady rovnakého zaobchádzania.
      </p>
      <p>
        Príklad: Navrhovateľke bol zamietnutý nárok na dôchodok určený pre osoby s „veľkými rodinami“. Aj keď mala požadovaný počet detí, jedno z jej detí nemalo grécke štátne občianstvo v dobe, keď navrhovateľka dosiahla dôchodkový vek. Táto situácia bola výsledkom predchádzajúceho rozhodnutia vlády zbaviť štátnej príslušnosti celú rodinu (čo samé osebe bolo poznamenané nezrovnalosťami), a potom opätovne priznať štátnu príslušnosť len trom z jej detí (keďže štvrté už uzavrelo manželstvo). ESĽP konštatoval, že politika zbavenia štátnej príslušnosti sa uplatňovala najmä v prípade gréckych moslimov a že zamietnutie dôchodku nemožno odôvodniť na základe ochrany gréckeho národa, keďže samotné toto odôvodnenie predstavovalo diskrimináciu na základe národnostného pôvodu.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu majetku">
      <p>
        Pod pojmom majetok rozumieme súhrn všetkých vecí, ktoré niekomu patria.
      </p>
      <p>
        Porušenie zásady rovnakého zaobchádzania z dôvodu majetku predstavuje ten prípad, keď v spojení s konkrétnym nárokom (napr. na sociálnu dávku) orgán verejnej moci tento nárok odmietne s odôvodnením na majetok diskriminovaného.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu oznámenia kriminality alebo inej protispoločenskej činnosti">
      <p>
        Ide o najnovšie diskriminačné dôvody, ktoré boli včlenené do ADZ novelou uskutočnenou zákonom č. 307/2014 o niektorých opatreniach súvisiacich s oznamovaním protispoločenskej činnosti. Predmetom zákona je úprava podmienky poskytovania ochrany osobám pred neoprávneným postihom v pracovnoprávnom vzťahu v súvislosti s oznamovaním kriminality alebo inej protispoločenskej činnosti, prevencia protispoločenskej činnosti a protikorupčného vzdelávania a výchovy. Ďalšími osobitnými zákonmi, ktorých predmetom je ochrana pred diskrimináciou z dôvodu oznámenia kriminality je zákon č. 54/2019 Z.z. o ochrane oznamovateľov protispoločenskej činnosti a zákon č. 247/2017 o obetiach trestných činov.
      </p>
    </Details>
    <Details summary="Diskriminácia z dôvodu iného postavenia">
      <p>
        Iné postavenie nemožno stotožniť s nijakou jednoznačne určenou a konkrétnou hodnotou alebo záujmom. Tento diskriminačný dôvod má povahu ústavného splnomocnenia na rozšírenie dôvodov ochrany pred diskrimináciou, z čoho vyplýva, že dôvody diskriminácie sú v ADZ uvedené len demonštratívne, t.j. nejde o konečný výpočet.
      </p>
    </Details>
  </Article>
);

export default CausesOfDiscrimination;
